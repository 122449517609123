const SEO = [
	{
		page: "home",
		description:
			"I am a fullstack developer with expertise in React and Java Spring.",
		keywords: ["Gang", "Gang H", "Gang Han"],
	},

	{
		page: "about",
		description:
			"I am a fullstack developer with expertise in React and Java Spring.",
		keywords: ["Gang", "Gang H", "Gang Han"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects during my study and internship, and I'm proud of the progress I've made.",
		keywords: ["Gang", "Gang H", "Gang Han"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Gang", "Gang H", "Gang Han"],
	},
];

export default SEO;
