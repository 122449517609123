const INFO = {
	main: {
		title: "Welcome to Gang Han's Personal Website!",
		name: "Gang Han.",
		email: "ganghanfw@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/MasonHan1997",
		github: "https://github.com/DueTonight",
		linkedin: "https://www.linkedin.com/in/masonhan/",
		instagram: "https://www.instagram.com/alwaysduetonight/",
	},

	homepage: {
		title: "Full Stack web developer and DevOps engineer.",
		description:
			"I am a newly graduated full stack developer with experience in React and Java Spring. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I'm Gang Han, a new software engineer looking forward to contribute to the industry.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. During my study at Carleton University, I have learned numerous programming languages, design patterns, and frameworks. Besides school, I also have a passion for latest skills that are demanded by the market such as React, Spring, Docker, Kubernete and OpenShift.",
	},


	projects: [
		{
			title: "Smart Bed System",
			description:
				`Developed a Machine Learning solution that intelligently tracks users’ REM cycles with biometric sensors to
				optimize wake-up times in collaboration with three peer students.`,
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
		},

		{
			title: "SurveyMonkey Web Application",
			description:
				`Led a team of three to develop a survey web application, offering functionalities akin to SurveyMonkey, using Java
				Spring.`,
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
		},

		{
			title: "Risk Game",
			description:
				`Collaborated in a team of three to develop a Risk game in Java, leveraging the MVC design pattern for efficient
				code organization and scalability.`,
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
		},

		{
			title: "Embedded System Project",
			description:
				`Designed an embedded program to count how many times a button is clicked, and output the result using LED.`,
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/c/c.png",
		},
	],
};

export default INFO;
